import { t } from '@lingui/macro'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import { Box, Button, Typography } from '@mui/material'
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridColumnVisibilityModel,
    GridPaginationModel,
    GridRowParams,
    GridRowSpacingParams,
    GridSortModel
} from '@mui/x-data-grid'
import { PaginateMeta } from '@om1/falcon-api'
import { GridPagination } from '@om1/platform-components'
import { Routes, toPath } from '@om1/platform-routing'
import { getStyledGrid } from '@om1/platform-ui-kit/src/styles/grids'
import { PlatformPermissions } from '@om1/platform-utils'
import { FunctionComponent, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { CohortListItem, CohortLoading, CohortTrackingValues } from '../../state'
import { CohortSizeWithLoader } from '../shared/CohortSizeWithLoader'

export type CohortListDataGridProps = {
    cohorts: CohortListItem[]
    cohortSizeLoading: CohortLoading
    paginationModel: GridPaginationModel
    paginationMeta: PaginateMeta
    sortModel: GridSortModel
    onPaginationModelChange: (params: GridPaginationModel) => void
    onSortModelChange: (params: GridSortModel) => void
    onDeleteView: (id: string) => void
    onDuplicate: (id: string) => void
    onRefreshCount: (id: string, name: string) => void
    onEdit: (payload: CohortTrackingValues) => void
    permissions: string[] | undefined
}

const StyledDataGrid = getStyledGrid(DataGrid) as typeof DataGrid

/**
 * A tabular / grid view used to display user and system cohorts.
 */
export const CohortListDataGrid: FunctionComponent<CohortListDataGridProps> = ({
    cohorts,
    cohortSizeLoading,
    paginationMeta,
    paginationModel,
    sortModel,
    onDeleteView,
    onDuplicate,
    onPaginationModelChange,
    onRefreshCount,
    onSortModelChange,
    onEdit,
    permissions
}) => {
    const handleDeleteView = useCallback((params: GridRowParams<CohortListItem>) => onDeleteView(params.row.id), [onDeleteView])
    const handleDuplicate = useCallback((params: GridRowParams<CohortListItem>) => onDuplicate(params.row.id), [onDuplicate])
    const handleEdit = useCallback(
        (params: GridRowParams<CohortListItem>) =>
            onEdit({
                id: params.row.id,
                name: params.row.name,
                analyticsDataset: params.row.analyticsDataset
            }),
        [onEdit]
    )
    const handleRefreshCount = useCallback(
        (params: GridRowParams<CohortListItem>) => onRefreshCount(params.row.id, params.row.name),
        [onRefreshCount]
    )
    const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
        return {
            top: params.isFirstVisible ? 0 : 4,
            bottom: params.isLastVisible ? 0 : 4
        }
    }, [])

    // Don't display the datasetName if we're showing system cohorts
    const isSystemCohortGrid = cohorts.some((c) => c.isSystem)
    const columnVisibilityModel: GridColumnVisibilityModel = isSystemCohortGrid ? { datasetName: false } : {}

    const renderMenuActions = useCallback(
        (params: GridRowParams<CohortListItem>) => {
            const { id, isSystem } = params.row
            const options: JSX.Element[] = []

            if (!isSystem && permissions?.includes(PlatformPermissions.UPDATE_COHORT)) {
                options.push(
                    <GridActionsCellItem
                        // MUI typing does not recognize the ability to assign the `component` prop here
                        // @ts-ignore
                        component={Link}
                        to={toPath(Routes.COHORTBUILD) + `/custom/${id}/edit`}
                        icon={<EditOutlinedIcon color='primary' />}
                        key='edit'
                        label={t`Edit Cohort`}
                        showInMenu
                        onClick={() => handleEdit(params)}
                    />
                )

                options.push(
                    <GridActionsCellItem
                        icon={<RefreshOutlinedIcon color='primary' />}
                        key='refresh-count'
                        disabled={cohortSizeLoading[id]}
                        label={t`Refresh Count`}
                        showInMenu
                        onClick={() => handleRefreshCount(params)}
                    />
                )
                if (permissions?.includes(PlatformPermissions.CREATE_COHORT)) {
                    options.push(
                        <GridActionsCellItem
                            icon={<ContentCopyIcon color='primary' />}
                            key='duplicate'
                            label={t`Duplicate`}
                            showInMenu
                            onClick={() => handleDuplicate(params)}
                        />
                    )
                }
                if (permissions?.includes(PlatformPermissions.DELETE_COHORT)) {
                    options.push(
                        <GridActionsCellItem
                            icon={<DeleteIcon color='primary' />}
                            key='delete'
                            label={t`Delete`}
                            showInMenu
                            onClick={() => handleDeleteView(params)}
                        />
                    )
                }
            }

            return options
        },
        [cohortSizeLoading, handleDeleteView, handleDuplicate, handleEdit, handleRefreshCount, permissions]
    )

    const columns = useMemo<GridColDef<CohortListItem>[]>(() => {
        const dateFormatter = new Intl.DateTimeFormat(undefined, { dateStyle: 'short' })

        return [
            {
                field: 'name',
                headerName: t`Name`,
                renderCell: (params) => (
                    <Box display='flex' flexDirection='column' gap={1} minWidth={0} maxWidth='100%'>
                        <Typography color='primary' fontWeight='bold' className='grid-content' noWrap>
                            {params.row.isSystem ? (
                                <>{params.row.name}</>
                            ) : (
                                <Button
                                    variant='text'
                                    component={Link}
                                    to={toPath(Routes.COHORTBUILD) + `/custom/${params.row.id}`}
                                    sx={{
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                        paddingLeft: '4px',
                                        paddingRight: '4px',
                                        display: 'inline-block',
                                        minWidth: 0
                                    }}
                                >
                                    {params.row.name}
                                </Button>
                            )}
                        </Typography>
                        <Box whiteSpace='normal'>
                            <Typography className='grid-content' noWrap paddingLeft={params.row.isSystem ? '0px' : '4px'}>
                                {params.row.description}
                            </Typography>
                        </Box>
                    </Box>
                ),
                flex: 3,
                sortable: true,
                filterable: false
            },
            {
                field: 'cohortSize',
                type: 'number',
                renderCell: (params) => {
                    return (
                        <CohortSizeWithLoader
                            cohortSize={params.row.cohortSize}
                            datasetSize={params.row.analyticsDataset.size!}
                            isStale={params.row.isStale}
                            sizeLoading={cohortSizeLoading[params.row.id]}
                            isSystem={params.row.isSystem}
                        />
                    )
                },
                headerName: t`Cohort Size`,
                width: 125,
                sortable: true,
                filterable: false
            },
            {
                field: 'datasetName',
                headerName: t`Dataset`,
                flex: 2,
                minWidth: 150,
                maxWidth: 250,
                sortable: false,
                filterable: false,
                renderCell: (params) => {
                    return params.row.analyticsDataset?.name
                }
            },
            {
                field: 'createdDttm',
                valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
                headerName: t`Created`,
                width: 110,
                sortable: true,
                filterable: false
            },
            {
                field: 'queryUpdatedDttm',
                valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
                headerName: t`Updated`,
                width: 110,
                sortable: true,
                filterable: false
            },
            {
                field: 'createdByEmail',
                headerName: t`Owner`,
                width: 250,
                sortable: false,
                filterable: false
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: '',
                getActions: renderMenuActions,
                width: 50,
                sortable: false,
                filterable: false
            }
        ]
    }, [renderMenuActions, cohortSizeLoading])

    return (
        <>
            <Box>
                <StyledDataGrid
                    autoHeight
                    disableColumnMenu
                    rowHeight={80}
                    getRowSpacing={getRowSpacing}
                    columnVisibilityModel={columnVisibilityModel}
                    columns={columns}
                    rows={cohorts}
                    slots={{ pagination: GridPagination }}
                    slotProps={{ pagination: { totalItems: paginationMeta.totalItems } }}
                    paginationModel={paginationModel}
                    sortModel={sortModel}
                    rowCount={paginationMeta.totalItems}
                    onPaginationModelChange={(model) => onPaginationModelChange(model)}
                    onSortModelChange={(model) => onSortModelChange(model)}
                />
            </Box>
        </>
    )
}
