import { keycloakActions } from '@om1/platform-authentication'
import { createResponse } from '@om1/platform-utils'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'

import { SweetPath } from 'sweet-path'

export type FalconSearchParams = { [key in 'limit' | 'page']?: string }

export type FalconPaginationMeta = {
    currentPage: number
    itemsPerPage: number
    totalItems: number
    totalPages: number
}

export type FalconPaginationQuery = {
    page?: number
    limit?: number
}

export interface FalconPaginationRes<T> {
    data: T
    meta: FalconPaginationMeta
}

/**
 * Makes a request to an FALCON api endpoint
 * @param path the path representing the server API to call
 * @param body the jsonifiable request body
 * @param abortSignal used to cancel the request if necessary
 */
export function createFalconAPI(
    baseUrl: string = '',
    enqueueSnackbar?: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
    isTokenExpired?: (minValidity?: number | undefined) => boolean
) {
    function bearer(token: string) {
        return `Bearer ${token}`
    }
    return async function fetchFalconAPI(
        path: SweetPath<string>,
        pathParams: {
            [key in string]: string
        },
        searchParams: FalconSearchParams | { [key in string]: string } | string,
        method: string,
        token?: string,
        body?: {},
        abortSignal?: AbortSignal
    ): Promise<[string, number]> {
        if (token && isTokenExpired && isTokenExpired()) {
            keycloakActions.sessionExpired()
        }
        const headers = {
            'Content-Type': 'application/json',
            Authorization: bearer(token!)
        }
        const url: URL = new URL(path.insert(pathParams) + '?' + new URLSearchParams(searchParams).toString(), baseUrl)
        try {
            const response = await fetch(url, {
                signal: abortSignal,
                method: method ? method : 'GET',
                headers: headers,
                ...(body
                    ? {
                          body: JSON.stringify(body)
                      }
                    : {})
            })
            const responseBody = await response.text()

            return [responseBody, response.status]
        } catch (error) {
            if (process.env.NODE_ENV !== 'production' && enqueueSnackbar) {
                enqueueSnackbar(`${(error as Error).message} ${url}`, { variant: 'error' })
            }
            return new Promise<[string, number]>((resolve) => {
                resolve([(error as Error).message, 500])
            })
        }
    }
}
export type FalconAPI = ReturnType<typeof createFalconAPI>

export const FALCON_SUCCESS = 'success'
export const FALCON_ERROR = 'error'
export const FALCON_UNAUTHORIZED = 'unauthorized'
export const FALCON_NOTFOUND = 'notfound'
export const FALCON_CONFLICT = 'conflict'
export const FALCON_BADREQUEST = 'badrequest'
export const FALCON_UNPROCESSABLE = 'unprocessable'

export const falconError = (message?: string) => createResponse(FALCON_ERROR, message)
export const falconSuccess = () => createResponse(FALCON_SUCCESS)
export const falconUnauthorized = () => createResponse(FALCON_UNAUTHORIZED)
export const falconBadRequest = () => createResponse(FALCON_BADREQUEST)
export const falconNotfound = () => createResponse(FALCON_NOTFOUND)
