import { t, Trans } from '@lingui/macro'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import { Card, CardActions, CardContent, Grid, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { StyledPagination } from '@om1/platform-components'
import { BasicCardStat } from '@om1/platform-components/BasicCard'
import { LightTooltip } from '@om1/platform-components/Tooltip'
import { Routes, toPath } from '@om1/platform-routing'
import { PlatformPermissions } from '@om1/platform-utils'
import { get } from 'lodash'
import { FunctionComponent, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { CohortListItem, CohortTrackingValues, ExceptFilterOperator } from '../../state'
import { hasPhenotypeBlock } from '../edit/utils/filter-import'
import { CohortSizeWithLoader } from '../shared/CohortSizeWithLoader'
import { CohortListDataGridProps } from './CohortListDataGrid'
import { sortByCohortField } from './utils/cohort-list-utils'

export type CohortListCardGridProps = CohortListDataGridProps

/**
 * A card view used to display user and system cohorts.
 */
export const CohortListCardGrid: FunctionComponent<CohortListCardGridProps> = ({
    cohorts,
    cohortSizeLoading,
    paginationMeta,
    paginationModel,
    sortModel,
    onDeleteView,
    onDuplicate,
    onPaginationModelChange,
    onRefreshCount,
    onEdit,
    permissions
}) => {
    const handlePageChange = (page: number) => onPaginationModelChange({ ...paginationModel, page })

    const handlePageSizeChange = (pageSize: number) => onPaginationModelChange({ page: 0, pageSize })

    const handleRefreshCount = useCallback((id: string, name: string) => onRefreshCount(id, name), [onRefreshCount])

    const sortedPaginatedCohorts = useMemo(() => {
        const { page, pageSize } = paginationModel
        const [{ field, sort }] = sortModel
        const start = pageSize * page
        const end = start + pageSize

        let localCohorts = [...cohorts]

        localCohorts.sort(sortByCohortField(field, sort))
        localCohorts = localCohorts.slice(start, end)

        return localCohorts
    }, [sortModel, paginationModel, cohorts])

    return (
        <>
            <Grid container spacing={2} columns={{ sm: 1, md: 2, lg: 3 }}>
                {sortedPaginatedCohorts.map((cohort) => (
                    <Grid item key={cohort.id} sm={1} md={1} lg={1} display='flex' flexDirection='column'>
                        <CohortListGridCard
                            cohort={cohort}
                            cohortSizeLoading={cohortSizeLoading[cohort.id]}
                            onDeleteView={onDeleteView}
                            onDuplicate={onDuplicate}
                            onEdit={onEdit}
                            onRefreshCount={handleRefreshCount}
                            permissions={permissions}
                        />
                    </Grid>
                ))}
            </Grid>
            <StyledPagination
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                page={paginationModel.page}
                pageSize={paginationModel.pageSize}
                pageSizeOptions={[5, 10, 20]}
                totalItems={paginationMeta.totalItems}
            />
        </>
    )
}

interface CohortListGridCardProps {
    cohort: CohortListItem
    cohortSizeLoading: boolean
    onDeleteView: (id: string) => void
    onDuplicate: (id: string) => void
    onEdit: (payload: CohortTrackingValues) => void
    onRefreshCount: (id: string, name: string) => void
    permissions: string[] | undefined
}

/**
 * A card that summarizes a single cohort.
 */
export const CohortListGridCard: FunctionComponent<CohortListGridCardProps> = ({
    cohort,
    cohortSizeLoading,
    onDeleteView,
    onDuplicate,
    onEdit,
    onRefreshCount,
    permissions
}) => {
    const dateFormatter = new Intl.DateTimeFormat(undefined, { dateStyle: 'short' })
    const actions = useMemo(() => {
        const actions: JSX.Element[] = []

        if (!cohort.isSystem && permissions?.includes(PlatformPermissions.UPDATE_COHORT)) {
            actions.push(
                <LightTooltip key={'editTooltip'} title={<Trans>Edit Cohort</Trans>} placement='top'>
                    <IconButton
                        key='edit'
                        color='primary'
                        component={Link}
                        to={toPath(Routes.COHORTBUILD) + `/custom/${cohort.id}/edit`}
                        aria-label={t`Edit Cohort`}
                        onClick={() => onEdit({ id: cohort.id, name: cohort.name, analyticsDataset: cohort.analyticsDataset })}
                    >
                        <EditOutlinedIcon />
                    </IconButton>
                </LightTooltip>
            )
            actions.push(
                <LightTooltip key={'refreshCountTooltip'} title={<Trans>Refresh Cohort Size</Trans>} placement='top'>
                    <span>
                        <IconButton
                            key='refreshCount'
                            color='primary'
                            aria-label={t`Refresh Count`}
                            disabled={cohortSizeLoading}
                            onClick={() => onRefreshCount(cohort.id, cohort.name)}
                        >
                            <RefreshOutlinedIcon />
                        </IconButton>
                    </span>
                </LightTooltip>
            )
            if (permissions?.includes(PlatformPermissions.CREATE_COHORT)) {
                actions.push(
                    <LightTooltip key={'duplicateTooltip'} title={<Trans>Duplicate</Trans>} placement='top'>
                        <IconButton key='duplicate' color='primary' aria-label={t`Duplicate`} onClick={() => onDuplicate(cohort.id)}>
                            <ContentCopyIcon />
                        </IconButton>
                    </LightTooltip>
                )
            }
            if (permissions?.includes(PlatformPermissions.DELETE_COHORT)) {
                actions.push(
                    <LightTooltip key={'deleteTooltip'} title={<Trans>Delete</Trans>} placement='top'>
                        <IconButton key='delete' color='primary' aria-label={t`Delete`} onClick={() => onDeleteView(cohort.id)}>
                            <DeleteOutlinedIcon />
                        </IconButton>
                    </LightTooltip>
                )
            }
        }

        return actions
    }, [cohort, cohortSizeLoading, onDuplicate, onDeleteView, onEdit, onRefreshCount, permissions])

    const hasPhenotype = useMemo(() => {
        const queryFilters: boolean[] = get(cohort, ['query', 'filters'], []).map((filter: ExceptFilterOperator) => hasPhenotypeBlock(filter))

        return queryFilters.some((f) => !!f)
    }, [cohort])

    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <CardContent sx={{ flex: 1 }}>
                <Box display='flex' flexDirection='column' gap={1}>
                    <Typography fontWeight='bold' fontSize={12} textTransform='uppercase' color='success.main'>
                        {cohort.analyticsDataset.name}
                    </Typography>
                    <Typography color='primary' fontSize={18} component='h3' noWrap>
                        {cohort.isSystem ? (
                            cohort.name
                        ) : (
                            <Link style={{ color: 'inherit', textDecoration: 'none' }} to={toPath(Routes.COHORTBUILD) + `/custom/${cohort.id}`}>
                                {cohort.name} {hasPhenotype}
                            </Link>
                        )}
                    </Typography>
                    <Typography fontSize={12}>{cohort.description}</Typography>
                </Box>
            </CardContent>

            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <BasicCardStat
                            id={`cohort-grid-size-${cohort.id}`}
                            label={<Trans>Cohort Size</Trans>}
                            value={
                                <CohortSizeWithLoader
                                    cohortSize={cohort.cohortSize}
                                    datasetSize={cohort.analyticsDataset.size!}
                                    isStale={cohort.isStale}
                                    sizeLoading={cohortSizeLoading}
                                    isSystem={cohort.isSystem}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BasicCardStat
                            id='cohort-grid-created'
                            label={<Trans>Created</Trans>}
                            value={dateFormatter.format(new Date(cohort.createdDttm))}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BasicCardStat
                            id='cohort-grid-updated'
                            label={<Trans>Updated</Trans>}
                            value={dateFormatter.format(new Date(cohort.queryUpdatedDttm))}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BasicCardStat id='cohort-grid-owner' label={<Trans>Owner</Trans>} value={cohort.createdByEmail || ''} />
                    </Grid>
                </Grid>
            </CardContent>

            <CardActions disableSpacing sx={{ justifyContent: 'flex-end', borderTop: 1, borderColor: 'grey.300' }}>
                {actions}
            </CardActions>
        </Card>
    )
}
